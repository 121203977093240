import React from "react";
import ImageFront from "../components/ImageFront";
import Wrapper from "../components/wrapper";
import Developpement from "../components/competenceContent";
import image from "../images/server.jpg";
import { frontImageText, title } from "../utils/competenceContent.json";
import Layout from "../components/layout";

const Equipe = () => (
    <Layout>
        <ImageFront
            surtitle={frontImageText.surtitle}
            title={frontImageText.title}
            span={frontImageText.span}
            subtext={frontImageText.subtext}
            background={image}
            opacityLevel={0.8}
        />
        <Wrapper>
            <h1>{title}</h1>
        </Wrapper>
        <Developpement />
    </Layout>
);

export default Equipe;

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Wrapper from "./wrapper";
import variable from "./variable";
import dev1 from "../images/dev.jpg";
import dev2 from "../images/dev2.jpg";
import dev3 from "../images/dev3.jpg";
import infra1 from "../images/infra.jpg";
import infra2 from "../images/infra2.jpg";
import infra3 from "../images/infra3.jpg";

import { type, developpement, infrastructure, architecture, outils, autres } from "../utils/competenceContent.json";

const competence = () => {
    let uniquekey = 0;
    const uniqueKey = () => {
        uniquekey += 1;
        return uniquekey;
    };

    return (
        <Wrapper>
            <Container>
                <div className="title-container ">
                    <h2 className="title-before">{type[0]}</h2>
                </div>
                <div className="unit-competence">
                    <div className="list-competence">
                        <ul>
                            {developpement.map(developpementItem => {
                                return (
                                    <li key={uniqueKey()}>
                                        <span>{developpementItem.language}</span> {developpementItem.framework}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="container-image">
                        <div>
                            <div className="image-unit dev1" />
                            <div className="image-unit dev2" />
                            <div className="image-unit dev3" />
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                <div className="title-container ">
                    <h2 className="title-before">{type[1]}</h2>
                </div>
                <div className="unit-competence">
                    <div className="list-competence">
                        <ul>
                            {infrastructure.map(infrastructureItem => {
                                return (
                                    <li key={uniqueKey()}>
                                        {infrastructureItem.type} : <span>{infrastructureItem.names}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="container-image">
                        <div>
                            <div className="image-unit infra1" />
                            <div className="image-unit infra2" />
                            <div className="image-unit infra3" />
                        </div>
                    </div>
                </div>
            </Container>
            <GridContainer>
                <Container>
                    <div className="title-container ">
                        <h2 className="title-before">DevOps</h2>
                    </div>
                    <p>
                        Nos DevOps semblent travailler dans l'ombre, mais ils sont toujours aux côtés de nos
                        développeurs.
                        <br />
                        <br />
                        Ils sont là pour faciliter leur quotidien en apportant une expertise sur des points comme le
                        système, l’architecture, la sécurité. Ils sont dans bien des cas des conseillers en bonnes
                        pratiques, prêts à aider la montée en compétence.
                    </p>
                    <LinkContainer>
                        <Link to="/DevOps/">Voir plus +</Link>
                    </LinkContainer>
                </Container>
                <Container>
                    <div className="title-container ">
                        <h2 className="title-before">Hebergement</h2>
                    </div>
                    <ul>
                        <li>Hebergement scalable</li>
                        <li>HAproxy</li>
                        <li>Développement d'applications distribués</li>
                        <li>Stockage distribué, haute performance</li>
                        <li>Base de données en cluster : Maria DB, Mongo</li>
                        <li>Virtualisation , virtual machine high availability</li>
                    </ul>
                </Container>

                <Container>
                    <div className="title-container ">
                        <h2 className="title-before">{type[2]}</h2>
                    </div>
                    <ul>
                        <li>{architecture.content}</li>
                    </ul>
                </Container>
                <Container>
                    <div className="title-container ">
                        <h2 className="title-before">{type[3]}</h2>
                    </div>
                    <ul>
                        <li>{outils.content}</li>
                    </ul>
                </Container>
                <Container>
                    <div className="title-container ">
                        <h2 className="title-before">{type[4]}</h2>
                    </div>
                    <ul>
                        <li>{autres.content}</li>
                    </ul>
                </Container>
            </GridContainer>
        </Wrapper>
    );
};

const Container = styled.div`
    margin-top: 40px;
    padding: 0 40px 15px 40px;
    border: 2px solid ${variable.lightPrimary};
    border-bottom: 4px solid ${variable.secondaryColor};
    border-radius: 5px 5px 0 0;
    @media screen and (max-width: 768px) {
        padding: 0 30px 15px 30px;
    }
    .title-container {
        display: table;
        background: #fff;
        margin: 0;
        padding: 6px 15px 6px 15px;
        margin-top: -21px;
        margin-left: -20px;
        h2 {
            line-height: 30px;
        }
    }
    ul {
        list-style: none;
        color: ${variable.bodyColor};
        padding: 0;
        span {
            color: ${variable.darkGrey};
            font-weight: 600;
        }
    }
    .unit-competence {
        display: flex;
        > div {
            flex: 1;
        }
        @media screen and (min-width: 768px) {
            .list-competence {
                padding-left: 20px;
            }
        }
        .container-image {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 768px) {
                display: none;
            }
            .image-unit {
                margin: 0 auto;
                height: 35px;
                width: 160px;
                background-size: cover;
                border-radius: 25px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    border-radius: 25px;
                    height: 100%;
                    width: 100%;
                    z-index: 2;
                    background-color: ${variable.primaryColor};
                    opacity: 0.5;
                }
                &:nth-child(1) {
                    margin-left: 10px;
                }
                &:nth-child(2) {
                    margin-top: -20px;
                    margin-left: 200px;
                }
                &:nth-child(3) {
                    margin-top: 5px;
                    margin-left: 40px;
                }
            }
            .dev1 {
                background-image: url(${dev1});
            }
            .dev2 {
                background-image: url(${dev2});
            }
            .dev3 {
                background-image: url(${dev3});
            }
            .infra1 {
                background-image: url(${infra1});
            }
            .infra2 {
                background-image: url(${infra2});
            }
            .infra3 {
                background-image: url(${infra3});
            }
        }
    }
`;
const GridContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    > div {
        width: 32%;
    }
    > div:nth-child(1),
    > div:nth-child(2) {
        width: 49%;
    }
    @media screen and (max-width: 992px) {
        > div {
            width: 100%;
        }
        > div:nth-child(1),
        > div:nth-child(2) {
            width: 100%;
        }
    }
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    a {
        text-decoration: underline;
    }

    a:visted {
        color: #0000ff;
    }
`;

export default competence;
